<template>
    <v-card flat>
        <v-card-text class="pb-0 mb-0">
            <v-row>
                <v-col style="color:#4E87DD; font-size:26px; padding-top:60px; padding-bottom:30px;" class="text-center">결제정보 등록 처리 중입니다</v-col>
            </v-row>
            <v-row>
                <v-col style="color:#555555; font-size:18px; padding-bottom:100px;" class="text-center">잠시만 기다려 주세요...</v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
    name: 'join_step_05',
    computed: {
        ...mapGetters({
            joinTid: 'join/getJoinTid'
        })
    },
    data: () => ({
    }),
    mounted () {
        this.$store.commit('join/setJoinStep', 5)
        this.doNext()
    },
    methods: {
        doNext() {
            let va = this

            this.$store.dispatch('join/getJoinStepInfo', {
                param: {
                    'join_tid': va.$route.params.join_tid
                },
                scb: (va) => {
                    va.$router.push(`/join/step04/${va.$route.params.join_tid}`)
                },
                fcb: (err, va) => {
                    va.$store.dispatch('openAlert', {
                        'message': '결제카드 등록을 실패하였습니다',
                        'sub_message': err == 'NO_TRAN' ? '인증 정보가 만료되었습니다' : '',
                        'okCb': (va) => {
                            if (err == 'NO_TRAN') {
                                va.$router.push('/join/step01')
                            } else {
                                va.$router.push(`/join/step04/${va.$route.params.join_tid}`)
                            }
                        },
                        'va': va,
                    })
                }, va: this
            })
        }
    }
}
</script>
<style>
</style>